<template>
  <div class="CEESAR-FileUpload">
        <input
            class="CEESAR-FileUploadField"
            type="file" :id="inputId" :ref="inputId"
            v-bind:value="value" v-on:input="$emit('input', $event.target.value)"/>

        <CEESAR-Button :label="buttonLabel" icon="upload_file" v-on:click="OnClick" />
  </div>
</template>

<script>
export default {
    name: "CEESAR-FileUpload",
    props:{
        label: {type: String, default: ""}, 
        buttonLabel: {type: String, default: ""}, 
        inputId: {type: String, required: true},
        value: {type: String, default: ""}, 
        required: {type: Boolean, default: false}
    },
    data(){
        return{
            isFocused: false
        };
    },
    methods:{
        OnClick(){
            let input = document.getElementById(this.inputId);
            input.click();
        }
    }
}
</script>

<style>

</style>